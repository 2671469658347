<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" md="6">
        <b-card>
          <div slot="header">
            <i class="icon-user-follow"></i> Autoroles
          </div>
          <h4 class="smalltitle">Autoroles</h4>
          <hr />These roles are assigned to any new member who joins. If a member had roles before and rejoins, the roles assigned is the superset of the autoroles and their old roles (given that reassign is enabled).
          <hr />
          <b-form-group>
            <label class="teenytinytitle" for="autorole_role_picker">Autoroles</label>

            <multiselect
              v-model="autoroles"
              :options="roles"
              :multiple="true"
              label="name"
              track-by="id"
              id="autorole_role_picker"
            >
              <template slot="tag" slot-scope="{option, search, remove}">
                <span
                  class="multiselect__tag"
                  :style="{'border-color': option ? '#' + option.color.toString(16) : '#b9bbbe'}"
                >
                  <i
                    aria-hidden="true"
                    tabindex="1"
                    @keydown.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon fakediscord"
                    :style="{'background-color': '#' + intToHex(option.color || 12172222)}"
                  ></i>
                  <span class="defaultcursor" v-text="option.name"></span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div :style="{'color': parseColor(props.option)}" class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
          </div>
        </b-card>
      </b-col>
      <b-col sm="6" md="6">
        <b-card>
          <div slot="header">
            <i class="icon-action-undo"></i> Reassigned roles
          </div>
          <h4 class="smalltitle">
            Sticky roles
            <font-awesome-icon
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
              id="restricted_question"
            />
            <b-popover
              triggers="hover"
              placement="top"
              target="restricted_question"
            >Prevents people from leaving the server to clear their roles. Useful if you want to prevent people from getting rid of their mutes or if you don't want to set up someone's role after they rejoin</b-popover>
          </h4>
          <hr />
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2">
              <label for="reassign_toggle_switch">
                <h5
                  class="tinytitle"
                  style="margin-right:0px;margin-left:10px"
                >Reassign roles upon rejoining</h5>
              </label>
            </b-col>
            <b-col cols="auto" class="p-2">
              <c-switch
                class="mx-1"
                id="reassign_toggle_switch"
                color="info"
                label
                variant="pill"
                v-bind="labelIcon"
                v-model="reassign"
              />
            </b-col>
          </b-row>
          <hr />
          <b-form-group>
            <label class="teenytinytitle" for="greet_channel_select">Blacklisted</label>
            <multiselect
              v-model="blacklisted"
              label="name"
              :options="roles"
              :multiple="true"
              track-by="id"
            >
              <template slot="tag" slot-scope="{option, search, remove}">
                <span
                  class="multiselect__tag"
                  :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
                >
                  <i
                    aria-hidden="true"
                    tabindex="1"
                    @keydown.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon fakediscord"
                    :style="{'background-color': '#' + intToHex(option.color || 12172222)}"
                  ></i>

                  <span class="defaultcursor" v-text="option.name"></span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div :style="{'color': parseColor(props.option)}" class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
          </div>
        </b-card>
      </b-col>

      <b-col sm="6" md="4"></b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes, timeout: 5000 });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

Vue.use(VueNotifications, toast_options);

export default {
  name: "Logging",
  components: {
    cSwitch,
    Multiselect
  },
  data: function() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      roles: [],
      autoroles: [],
      reassign: false,
      blacklisted: [],
      hierarchy: 0
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied",
      timeout: 5000
    }
  },
  methods: {
    put_settings() {
      this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/autoroles", {
          autoroles: this.autoroles.map(x => x.id),
          reassign: this.reassign,
          blacklisted: this.blacklisted.map(x => x.id)
        })
        .then(this.showSuccessMsg());
    },
    LightenDarkenColor(col, amt) {
      //col = this.intToHex(col);

      return (
        ((col & 0x0000ff) + amt) |
        ((((col >> 8) & 0x00ff) + amt) << 8) |
        (((col >> 16) + amt) << 16)
      ).toString(16);
    },
    intToHex(int) {
      return int.toString(16);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/autoroles")
      .then(r => {
        this.roles = r.data.roles;
        this.autoroles =
          this.roles.filter(x => r.data.autoroles.includes(x.id)) || [];
        this.reassign = r.data.reassign;
        this.blacklisted =
          this.roles.filter(x => r.data.blacklisted.includes(x.id)) || [];
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/autoroles")
      .then(r => {
        this.roles = r.data.roles;
        this.autoroles =
          this.roles.filter(x => r.data.autoroles.includes(x.id)) || [];
        this.reassign = r.data.reassign;
        this.blacklisted =
          this.roles.filter(x => r.data.blacklisted.includes(x.id)) || [];
        this.hierarchy = r.data.hierarchy;
        this.$Progress.finish();
      });
  }
};
</script>
